.main {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
}

h1 {
    margin: 10px;
    font-size: 40px;
    color: rgb(1, 1, 59);
}

.searchForm {
    width: 30%;
    left: 0px;
    overflow-y: scroll;
}
.dropBox{
    width: 50%
}

ul li {
    font-size: 20px;
}
.splitScreen{
    display: flex;
    flex-direction: row;
}