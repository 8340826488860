body {
    font-family: "open sans", sans-serif;
    font-size: 16px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead th {
    text-align: left;
    border-bottom: 2px solid black;
  }
  
  tbody td {
    padding: 1.6em;
    border-bottom: 1px solid #ccc;
  }
  
  tbody tr:hover {
    background-color: #eee;
  }
  