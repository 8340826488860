.feed {
    flex: 1;
    padding: 10px 150px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
}

.requests {
    width: 50%;
}
.responded{
    width: 50%;
    margin-left: 5px;
}
